/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getScreenQueueHistory = /* GraphQL */ `
  query GetScreenQueueHistory($id: ID!) {
    getScreenQueueHistory(id: $id) {
      id
      userId
      screenQueueID
      screens {
        items {
          id
          screenQueueID
          providerName
          screenName
          authorName
          url
          addedByIp
          isStream
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listScreenQueueHistorys = /* GraphQL */ `
  query ListScreenQueueHistorys(
    $filter: ModelScreenQueueHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScreenQueueHistorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        screenQueueID
        screens {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getScreenQueue = /* GraphQL */ `
  query GetScreenQueue($id: ID!) {
    getScreenQueue(id: $id) {
      id
      userId
      screens {
        items {
          id
          screenQueueID
          providerName
          screenName
          authorName
          url
          addedByIp
          isStream
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listScreenQueues = /* GraphQL */ `
  query ListScreenQueues(
    $filter: ModelScreenQueueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScreenQueues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        screens {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getScreen = /* GraphQL */ `
  query GetScreen($id: ID!) {
    getScreen(id: $id) {
      id
      screenQueueID
      providerName
      screenName
      authorName
      url
      addedByIp
      isStream
      duration
      createdAt
      updatedAt
    }
  }
`;
export const listScreens = /* GraphQL */ `
  query ListScreens(
    $filter: ModelScreenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScreens(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        screenQueueID
        providerName
        screenName
        authorName
        url
        addedByIp
        isStream
        duration
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
