import React from 'react'
import { Container, Box } from '@material-ui/core'
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom'

import withTracker from './hooks/withTracker'
import Theater from './components/Theater'
import Copyright from './components/Copyright'
import Authentication from './components/Authentication'
import Privacy from './components/Privacy'
import Terms from './components/Terms'

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/auth" exact component={withTracker(Authentication)} />
        <Route path="/terms" exact component={withTracker(Terms)} />
        <Route path="/privacy" exact component={withTracker(Privacy)} />
        <Route path="/" exact component={withTracker(Theater)} />
      </Switch>
    </BrowserRouter>
  )
}

export default App
