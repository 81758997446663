import React, { Fragment, useState, useEffect } from 'react'
import {
  Typography,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  ListItemSecondaryAction,
  Checkbox,
  Box,
  TextField,
  Paper,
} from '@material-ui/core'
import { AmplifySignOut } from '@aws-amplify/ui-react'
import { Auth } from 'aws-amplify'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'

const Settings = ({
  purgeWhenEnded,
  setPurgeWhenEnded,
  maxScreens,
  setMaxScreens,
}) => {
  const [user, setUser] = useState(null)
  const [authState, setAuthState] = useState()

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState)
      setUser(authData)
    })
  }, [authState, user])

  return (
    <Fragment>
      <ListSubheader>Settings</ListSubheader>
      <Paper>
        <List>
          <ListItem>
            <ListItemText primary="Remove screen when video ends?" />
            <ListItemSecondaryAction>
              <Checkbox
                edge="end"
                onChange={() => setPurgeWhenEnded(!purgeWhenEnded)}
                checked={purgeWhenEnded}
                inputProps={{ 'aria-label': 'remove screen when video ends' }}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText primary="Max Screens:" />
            <ListItemSecondaryAction>
              <TextField
                value={maxScreens}
                onChange={(e) => setMaxScreens(e.currentTarget.value)}
                type="number"
                variant="outlined"
                style={{ width: 72 }}
                margin="dense"
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Paper>
      {authState === AuthState.SignedIn && user ? (
        <Box padding={1}>
          <AmplifySignOut />
        </Box>
      ) : null}
    </Fragment>
  )
}

export default Settings
