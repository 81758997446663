import React, { Fragment, useState, useEffect } from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Drawer,
  Fade,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Button,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  ListSubheader,
} from '@material-ui/core'
import {
  LiveTv as LiveTvIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Gavel as GavelIcon,
  Assignment as AssignmentIcon,
  Lock as LockIcon,
} from '@material-ui/icons'
import Amplify, { API, graphqlOperation, Auth } from 'aws-amplify'
import { AmplifySignOut } from '@aws-amplify/ui-react'
import { Link } from 'react-router-dom'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'

import ScreenContext from '../lib/ScreenContext'
import { getScreenQueue } from '../graphql/queries'
import { createScreenQueue, deleteScreen } from '../graphql/mutations'
import AddScreenInput from './AddScreenInput'
import HistoryList from './HistoryList'
import Queue from './Queue'
import Settings from './Settings'
import CurrentlyPlaying from './CurrentlyPlaying'

const drawerWidth = 360

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: '#000',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: '#e3e3e3',
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 0),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  siteTitle: {
    display: 'flex',
    textDecoration: 'none',
    boxShadow: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 9,
  },
  drawerIcon: {
    paddingLeft: 9,
    fontSize: 42,
  },
  loginButton: {
    color: '#fff',
    backgroundColor: '#ff9900',
    marginLeft: 9,
    marginRight: 9,
    height: 66,
  },
}))

const bottomMenus = [
  {
    text: 'Privacy Policy',
    url: '/privacy',
    icon: <AssignmentIcon />,
  },
  {
    text: 'Terms of Use',
    url: '/terms',
    icon: <GavelIcon />,
  },
]

const NavDrawer = ({ children }) => {
  const fadeColors = [
    '#FFF',
    // '#000',
    // '#ff0000',
    // '#0000fe',
    // '#00ff00',
    '#FFFF00',
  ]
  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = useState(false)
  const [user, setUser] = useState({})
  const [authState, setAuthState] = useState('signedout')
  const [isFaded, setIsFaded] = useState(true)
  const [purgeWhenEnded, setPurgeWhenEnded] = useState(true)
  const [maxScreens, setMaxScreens] = useState(4)
  const [screens, setScreens] = useState([])
  const [hamburgerColor, setHamburgerColor] = useState(fadeColors[0])
  const [screensCurrentlyPlaying, setScreensCurrentlyPlaying] = useState([])
  const [screensQueued, setScreensQueued] = useState([])
  const [screensHistory, setScreensHistory] = useState([])

  const fadeTime = 1000

  useEffect(() => {
    // get youtube playlist of news for non-authenticated users
    if (authState !== AuthState.SignedIn || !user) {
      fetchNewsScreenQueue()
    }

    var count = 0
    // Interval Fade
    setInterval(() => {
      setIsFaded((prev) => !prev)
    }, fadeTime)
    // Interval Change Data
    setInterval(() => {
      if (count == fadeColors.length - 1) {
        count = 0
      } else count++
      setHamburgerColor(fadeColors[count])
    }, fadeTime * 2)

    return onAuthUIStateChange(async (nextAuthState, authData) => {
      if (nextAuthState === AuthState.SignedIn && authData) {
        const screenQueueResponse = await API.graphql(
          graphqlOperation(getScreenQueue, { id: authData.username }),
        )

        if (
          !screenQueueResponse.data.getScreenQueue ||
          !screenQueueResponse.data.getScreenQueue.id
        ) {
          const screenQueue = {
            id: authData.username,
            userId: authData.username,
          }
          await API.graphql(
            graphqlOperation(createScreenQueue, { input: screenQueue }),
          )
        }

        fetchScreenQueue(authData.username)
      } else {
        fetchNewsScreenQueue()
      }

      setAuthState(nextAuthState)
      setUser(authData || {})
    })
  }, [authState])

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const fetchNewsScreenQueue = async () => {
    try {
      const apiName = 'screenrScreens'
      const path = '/screen'
      const myInit = {
        // OPTIONAL
        body: {}, // replace this with attributes you need
        headers: {}, // OPTIONAL
      }

      const playlist = await API.get(apiName, path, myInit)

      const newPlaying = playlist.slice(0, maxScreens)
      const newQueue = playlist.slice(maxScreens, maxScreens.length)

      setScreensCurrentlyPlaying(newPlaying)
      setScreensQueued(newQueue)
    } catch (err) {
      console.error('Failed to get generic screen playlist')
    }
  }

  const fetchScreenQueue = async (username) => {
    try {
      const screenData = await API.graphql(
        graphqlOperation(getScreenQueue, { id: username || user.username }),
      )

      const screens = screenData.data.getScreenQueue.screens.items

      const newPlaying = screens.slice(0, maxScreens)
      const newQueue = screens.slice(maxScreens, maxScreens.length)

      setScreensCurrentlyPlaying(newPlaying)
      setScreensQueued(newQueue)
    } catch (err) {
      console.error('error fetching screens: ', err)
    }
  }

  const removeCurrentScreen = async (screenId) => {
    let screenIndex = null
    for (let i = 0; i < screensCurrentlyPlaying.length; i++) {
      if (screensCurrentlyPlaying[i].id === screenId) {
        screenIndex = i
        continue
      }
    }

    if (screenIndex !== null) {
      let currentPlaylist = screensCurrentlyPlaying

      // If there are screens queued then grab the next screen and put it where the removed screen index of the screensCurrentlyPlaying array
      if (screensQueued.length > 0) {
        currentPlaylist[screenIndex] = screensQueued.splice(0, 1)[0]
      } else {
        currentPlaylist.splice(screenIndex, 1)
      }

      setScreensCurrentlyPlaying(currentPlaylist)
      setScreensQueued(screensQueued)

      if (user.username) {
        try {
          const screenData = await API.graphql(
            graphqlOperation(deleteScreen, { input: { id: screenId } }),
          )
        } catch (err) {
          console.error('Error removing screen from current playlist: ', err)
        }
      }
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        color="transparent"
        elevation={0}
      >
        <Toolbar>
          <Fade
            in={isFaded}
            timeout={{
              enter: fadeTime * 2,
              exit: fadeTime * 2,
            }}
          >
            <IconButton
              style={{ backgroundColor: hamburgerColor }}
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, open && classes.hide)}
            >
              <LiveTvIcon style={{ color: isFaded ? '#FFF' : '#000' }} />
            </IconButton>
          </Fade>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
          <Typography
            className={classes.siteTitle}
            variant="h6"
            noWrap
            component={Link}
            to="/"
          >
            <Link
              href="#"
              underline="none"
              className={classes.siteTitle}
              to="/"
            >
              ScreenR
              <LiveTvIcon className={classes.drawerIcon} />
            </Link>
          </Typography>
        </div>

        {authState === AuthState.SignedIn && user && (
          <Box padding={1}>
            <Typography variant="caption">
              Add a video URL from YouTube, Twitch, Vimeo, or Facebook
            </Typography>
            <AddScreenInput
              screensQueued={screensQueued}
              setScreensQueued={setScreensQueued}
              screensCurrentlyPlaying={screensCurrentlyPlaying}
              setScreensCurrentlyPlaying={setScreensCurrentlyPlaying}
              maxScreens={maxScreens}
            />
          </Box>
        )}
        <CurrentlyPlaying
          screens={screensCurrentlyPlaying}
          removeCurrentScreen={removeCurrentScreen}
        />
        <Queue screens={screensQueued} />

        {authState === AuthState.SignedIn && user && (
          <HistoryList screens={screensHistory} />
        )}

        <Settings
          purgeWhenEnded={purgeWhenEnded}
          setPurgeWhenEnded={setPurgeWhenEnded}
          maxScreens={maxScreens}
          setMaxScreens={setMaxScreens}
        />

        {(authState === AuthState.SignedOut || !user) && (
          <Fragment>
            <ListSubheader>Customize the video queue</ListSubheader>
            <Button
              variant="contained"
              className={classes.loginButton}
              component={Link}
              to="/auth"
            >
              Login
            </Button>
          </Fragment>
        )}

        <List>
          {bottomMenus.map((menu, index) => (
            <ListItem
              button
              key={btoa(menu.text + Math.random())}
              component={Link}
              to={menu.url}
            >
              <ListItemIcon>{menu.icon}</ListItemIcon>
              <ListItemText primary={menu.text} />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <ScreenContext.Provider
          value={{
            screensCurrentlyPlaying,
            screensQueued,
            screensHistory,
            purgeWhenEnded,
            user,
            removeCurrentScreen,
          }}
        >
          {children}
        </ScreenContext.Provider>
      </main>
    </div>
  )
}

export default NavDrawer
