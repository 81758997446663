import React, { useEffect } from 'react'
import ReactGA from 'react-ga4'

import NavDrawer from '../components/NavDrawer'

ReactGA.initialize('G-X6JSBC22BC')

export default (WrappedComponent, options = {}) => {
  const trackPage = (page) => {
    ReactGA.set({
      page,
      ...options,
    })
    ReactGA.send({ hitType: 'pageview', page })
  }

  const HOC = (props) => {
    useEffect(() => trackPage(props.location.pathname), [
      props.location.pathname,
    ])

    return (
      <NavDrawer>
        <WrappedComponent {...props} />
      </NavDrawer>
    )
  }

  return HOC
}
