import React, { Fragment } from 'react'
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
} from '@material-ui/core'
import { Link } from 'react-router-dom'

const HistoryList = ({ screenHistory = [] }) => {
  return (
    <Fragment>
      <ListSubheader>History</ListSubheader>
      <Paper>
        <List>
          {screenHistory.length > 0 ? (
            screenHistory.map((screen) => (
              <ListItem key={btoa(`${screen.url}-${i}`)}>
                <ListItemText
                  primary={screen.screenName}
                  secondary={screen.authorName}
                />
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText
                primary="Your history is a mystery"
                secondary="History coming soon!"
              />
            </ListItem>
          )}
        </List>
      </Paper>
    </Fragment>
  )
}

export default HistoryList
