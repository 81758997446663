import React, { Fragment } from 'react'
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  ListItemSecondaryAction,
  IconButton,
  Divider,
  Paper,
} from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'
import { Link } from 'react-router-dom'

const CurrentlyPlaying = ({ screens, removeCurrentScreen }) => {
  return (
    <Fragment>
      <ListSubheader>Currently Showing</ListSubheader>
      <Paper>
        <List>
          {screens.length > 0 ? (
            screens.map(
              (screen, i) =>
                screen && (
                  <Fragment key={btoa(`${screen.url}-${i}`)}>
                    <ListItem>
                      <ListItemText
                        primary={screen.screenName}
                        secondary={screen.authorName}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => removeCurrentScreen(screen.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                    {screens.length - 1 !== i ? <Divider /> : null}
                  </Fragment>
                ),
            )
          ) : (
            <ListItem key={0} button component={Link} to="/">
              <ListItemText primary="No videos currently playing" />
            </ListItem>
          )}
        </List>
      </Paper>
    </Fragment>
  )
}

export default CurrentlyPlaying
