import React, { Fragment } from 'react'
import {
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Divider,
} from '@material-ui/core'
import { Link } from 'react-router-dom'

const Queue = ({ screens }) => {
  return (
    <Fragment>
      <ListSubheader>Coming up...</ListSubheader>
      <Paper>
        <List>
          {screens.length > 0 ? (
            screens.map((screen, i) => (
              <Fragment key={btoa(`${screen.url}-${i}`)}>
                <ListItem>
                  <ListItemText
                    primary={screen.screenName}
                    secondary={screen.authorName}
                  />
                </ListItem>
                {screens.length - 1 !== i ? <Divider /> : null}
              </Fragment>
            ))
          ) : (
            <ListItem key={0} button component={Link} to="/">
              <ListItemText primary="No videos in the queue" />
            </ListItem>
          )}
        </List>
      </Paper>
    </Fragment>
  )
}

export default Queue
