import React, { useState, useEffect, useContext } from 'react'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import ReactPlayer from 'react-player'
import { API, graphqlOperation, Auth } from 'aws-amplify'

import ScreenContext from '../lib/ScreenContext'
import NavDrawer from '../components/NavDrawer'
import { getScreenQueueHistory } from '../graphql/queries'
import { createScreen, createScreenQueueHistory } from '../graphql/mutations'

const useStyles = makeStyles((theme) => ({
  typography: {
    color: '#FFF',
  },
}))

const Theater = () => {
  const {
    screensCurrentlyPlaying = [],
    purgeWhenEnded,
    user,
    removeCurrentScreen,
  } = useContext(ScreenContext)
  const classes = useStyles()

  const onScreenEnd = async (index) => {
    if (user.username) {
      let newHistoryScreen = screensCurrentlyPlaying[index]

      delete newHistoryScreen.createdAt
      delete newHistoryScreen.updatedAt

      // Add to user's watch history
      try {
        const screenQueueHistoryResponse = await API.graphql(
          graphqlOperation(getScreenQueueHistory, { id: user.username }),
        )

        if (!screenQueueHistoryResponse.data.getScreenQueueHistory?.id) {
          const screenQueueHistory = {
            id: user.username,
            userId: user.username,
            screenQueueID: newHistoryScreen.id,
          }
          await API.graphql(
            graphqlOperation(createScreenQueueHistory, {
              input: screenQueueHistory,
            }),
          )
        }
      } catch (err) {
        console.log('Error creating screen history: ', err)
      }
    }

    // Remove from theater view
    removeCurrentScreen(screensCurrentlyPlaying[index].id)
  }

  return (
    <NavDrawer>
      <Grid
        container
        align="center"
        justify="center"
        alignItems="center"
        spacing={0}
        direction="row"
        style={{
          position: 'absolute',
          top: 0,
          backgroundColor: '#000',
          height: '100vh',
          paddingLeft: 0,
        }}
      >
        {screensCurrentlyPlaying.length > 0 &&
        screensCurrentlyPlaying.filter((x) => x !== undefined).length > 0 ? (
          screensCurrentlyPlaying.map((screen, i) =>
            screen && screen.url ? (
              <Grid
                key={screen.screenId}
                item
                xs={12}
                md={
                  screensCurrentlyPlaying.filter((x) => x !== undefined)
                    .length < 3
                    ? 12
                    : 6
                }
              >
                <ReactPlayer
                  url={screen.url}
                  playing={true}
                  muted={true}
                  controls={true}
                  width={
                    screensCurrentlyPlaying.filter((x) => x !== undefined)
                      .length < 3
                      ? 'auto'
                      : '50vw'
                  }
                  onEnded={purgeWhenEnded ? () => onScreenEnd(i) : () => {}}
                  config={{
                    facebook: {
                      attributes: {
                        'data-width': '640px',
                        'data-height': '360px',
                      },
                    },
                  }}
                />
              </Grid>
            ) : null,
          )
        ) : (
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.typography}>
              No screens to play...
            </Typography>
            <Typography variant="subtitle2" className={classes.typography}>
              Either login to customize the video queue or refresh the page to
              fetch a new set of news videos
            </Typography>
          </Grid>
        )}
      </Grid>
    </NavDrawer>
  )
}

export default Theater
