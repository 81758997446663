/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:44855390-672d-499c-ac41-562640dcaf7f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_w5JVn9PXI",
    "aws_user_pools_web_client_id": "6tld5np47n9v3tl5qfa85e8329",
    "oauth": {
        "domain": "screenra524016e-a524016e-prod.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://screenr.tv/",
        "redirectSignOut": "https://screenr.tv/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://dfg3i52wzfb5devuanjndnzi3i.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "screenrScreens",
            "endpoint": "https://5hejtevr9h.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
