import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, InputBase, Divider, IconButton } from '@material-ui/core'
import { Add as AddIcon, ScreenShare } from '@material-ui/icons'
import { API, graphqlOperation, Auth } from 'aws-amplify'

import { createScreen, createScreenQueue } from '../graphql/mutations'
import { getScreenQueue } from '../graphql/queries'
import { getScreenMetadata } from '../lib/getScreenMetadata'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '3px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 342,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}))

const initialState = {
  screenName: '',
  providerName: '',
  url: '',
  addedByIp: '',
  isStream: false,
}

const AddScreenInput = ({
  screensQueued,
  setScreensQueued,
  screensCurrentlyPlaying,
  setScreensCurrentlyPlaying,
  maxScreens,
}) => {
  const classes = useStyles()
  const [formState, setFormState] = useState(initialState)

  const addScreenToQueue = async (e) => {
    e.preventDefault()

    let domain = new URL(formState.url)
    domain = domain.hostname.replace('www.', '')

    // Handle youtube videos with youtu.be shortened domain
    if (domain === 'youtu.be') {
      domain = 'youtube.com'
      const videoId = formState.url.split('/')[
        formState.url.split('/').length - 1
      ]
      formState.url = `https://www.youtube.com/watch?v=${videoId}`
    }

    try {
      // Get user
      const user = await Auth.currentAuthenticatedUser()

      if (!formState.url) return

      const apiName = 'screenrScreens'
      const path = '/screen'
      const myInit = {
        // OPTIONAL
        body: {
          url: formState.url,
        }, // replace this with attributes you need
        headers: {}, // OPTIONAL
      }
      // Get video metadata
      const screenMetadata = await API.post(apiName, path, myInit)

      const screen = {
        ...formState,
        screenQueueID: user.username,
        screenName: screenMetadata ? screenMetadata.screenName : formState.url,
        authorName: screenMetadata ? screenMetadata.authorName : '',
        providerName: domain,
        isStream: screenMetadata ? screenMetadata.isStream : false,
        duration: screenMetadata ? screenMetadata.duration : 0,
      }

      await API.graphql(graphqlOperation(createScreen, { input: screen }))
      setFormState(initialState)
      if (
        screensCurrentlyPlaying.filter((x) => x !== undefined).length <
        maxScreens
      ) {
        setScreensCurrentlyPlaying([
          ...screensCurrentlyPlaying.filter((x) => x !== undefined),
          screen,
        ])
      } else {
        setScreensQueued([
          ...screensQueued.filter((x) => x !== undefined),
          screen,
        ])
      }
    } catch (err) {
      console.error('Error adding screen:', err)
    }
  }

  const setInput = (key, value) => {
    setFormState({ ...formState, [key]: value })
  }

  return (
    <Paper component="form" className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder="Add video to the queue"
        inputProps={{ 'aria-label': 'Add video to the queue' }}
        name="url"
        value={formState.url}
        onChange={(e) => {
          e.preventDefault()
          setInput(e.currentTarget.name, e.currentTarget.value)
        }}
      />
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        color="primary"
        className={classes.iconButton}
        aria-label="Add screen"
        onClick={addScreenToQueue}
      >
        <AddIcon />
      </IconButton>
    </Paper>
  )
}

export default AddScreenInput
