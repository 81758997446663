/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createScreenQueueHistory = /* GraphQL */ `
  mutation CreateScreenQueueHistory(
    $input: CreateScreenQueueHistoryInput!
    $condition: ModelScreenQueueHistoryConditionInput
  ) {
    createScreenQueueHistory(input: $input, condition: $condition) {
      id
      userId
      screenQueueID
      screens {
        items {
          id
          screenQueueID
          providerName
          screenName
          authorName
          url
          addedByIp
          isStream
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateScreenQueueHistory = /* GraphQL */ `
  mutation UpdateScreenQueueHistory(
    $input: UpdateScreenQueueHistoryInput!
    $condition: ModelScreenQueueHistoryConditionInput
  ) {
    updateScreenQueueHistory(input: $input, condition: $condition) {
      id
      userId
      screenQueueID
      screens {
        items {
          id
          screenQueueID
          providerName
          screenName
          authorName
          url
          addedByIp
          isStream
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteScreenQueueHistory = /* GraphQL */ `
  mutation DeleteScreenQueueHistory(
    $input: DeleteScreenQueueHistoryInput!
    $condition: ModelScreenQueueHistoryConditionInput
  ) {
    deleteScreenQueueHistory(input: $input, condition: $condition) {
      id
      userId
      screenQueueID
      screens {
        items {
          id
          screenQueueID
          providerName
          screenName
          authorName
          url
          addedByIp
          isStream
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createScreenQueue = /* GraphQL */ `
  mutation CreateScreenQueue(
    $input: CreateScreenQueueInput!
    $condition: ModelScreenQueueConditionInput
  ) {
    createScreenQueue(input: $input, condition: $condition) {
      id
      userId
      screens {
        items {
          id
          screenQueueID
          providerName
          screenName
          authorName
          url
          addedByIp
          isStream
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateScreenQueue = /* GraphQL */ `
  mutation UpdateScreenQueue(
    $input: UpdateScreenQueueInput!
    $condition: ModelScreenQueueConditionInput
  ) {
    updateScreenQueue(input: $input, condition: $condition) {
      id
      userId
      screens {
        items {
          id
          screenQueueID
          providerName
          screenName
          authorName
          url
          addedByIp
          isStream
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteScreenQueue = /* GraphQL */ `
  mutation DeleteScreenQueue(
    $input: DeleteScreenQueueInput!
    $condition: ModelScreenQueueConditionInput
  ) {
    deleteScreenQueue(input: $input, condition: $condition) {
      id
      userId
      screens {
        items {
          id
          screenQueueID
          providerName
          screenName
          authorName
          url
          addedByIp
          isStream
          duration
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createScreen = /* GraphQL */ `
  mutation CreateScreen(
    $input: CreateScreenInput!
    $condition: ModelScreenConditionInput
  ) {
    createScreen(input: $input, condition: $condition) {
      id
      screenQueueID
      providerName
      screenName
      authorName
      url
      addedByIp
      isStream
      duration
      createdAt
      updatedAt
    }
  }
`;
export const updateScreen = /* GraphQL */ `
  mutation UpdateScreen(
    $input: UpdateScreenInput!
    $condition: ModelScreenConditionInput
  ) {
    updateScreen(input: $input, condition: $condition) {
      id
      screenQueueID
      providerName
      screenName
      authorName
      url
      addedByIp
      isStream
      duration
      createdAt
      updatedAt
    }
  }
`;
export const deleteScreen = /* GraphQL */ `
  mutation DeleteScreen(
    $input: DeleteScreenInput!
    $condition: ModelScreenConditionInput
  ) {
    deleteScreen(input: $input, condition: $condition) {
      id
      screenQueueID
      providerName
      screenName
      authorName
      url
      addedByIp
      isStream
      duration
      createdAt
      updatedAt
    }
  }
`;
