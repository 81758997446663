import React, { useState, useEffect } from 'react'
import {
  AmplifyAuthenticator,
  AmplifySignUp,
  AmplifySignIn,
} from '@aws-amplify/ui-react'
import { Redirect } from 'react-router-dom'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'

const Authentication = () => {
  const [authState, setAuthState] = useState()
  const [user, setUser] = useState()

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState)
      setUser(authData)
    })
  }, [])

  return authState === AuthState.SignedIn && user ? (
    <Redirect to="/" />
  ) : (
    <AmplifyAuthenticator usernameAlias="email">
      <AmplifySignUp slot="sign-up" usernameAlias="email" />
      <AmplifySignIn slot="sign-in" usernameAlias="email" />
    </AmplifyAuthenticator>
  )
}

export default Authentication
